import { ImgProxy } from '~/helpers/ImgProxy';

export function getImage(src, { modifiers, baseURL } = {}, { options, $img }) {
    const { width, height } = modifiers;

    const operations = [width, height ?? width];
    const operationsString = operations.join('x');

    let newSrc;
    if (!src.startsWith('https://')) {
        newSrc = src;
    } else {
        newSrc = ImgProxy.getResizeServicePath(operationsString, src);
    }

    return {
        url: newSrc,
    };
}
