
import * as nginxRuntime$peG1AolT0s from '/buddy/adamhall-sw6/frontends/ahshop/providers/nginx/index.js'
import * as ipxRuntime$FvNL6l854l from '/buddy/adamhall-sw6/frontends/ahshop/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "3xl": 4096
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "i3.ytimg.com",
    "ah-eventtech.diwc.de",
    "blog.adamhall.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['nginx']: { provider: nginxRuntime$peG1AolT0s, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$FvNL6l854l, defaults: {} }
}
        