<script setup lang="ts">
import type { Media } from '@shopware-pwa/types';
import type { DisplayMode } from '@shopware-pwa/composables-next';
import type { AhMedia } from '~/types/models/media';
import type { Schemas } from '#shopware';

const props = withDefaults(
    defineProps<{
        media?: Schemas['Media'] | Media | Partial<Media> | null;
        mediaSrc?: string | null;
        sizes?: string;
        lazyLoad?: boolean;
        width?: string | number;
        height?: string | number;
        displayMode?: DisplayMode;
        preload?: boolean;
        autoPreload?: boolean;
        noDensities?: boolean;
    }>(),
    {
        media: null,
        mediaSrc: null,
        sizes: 'xs:100vw',
        lazyLoad: true,
        width: '',
        height: '',
        displayMode: 'standard',
        preload: false,
        autoPreload: true,
        noDensities: false,
    },
);

const sectionPosition = inject<number>('sectionPosition', 999);
const blockPosition = inject<number>('blockPosition', 999);

const preloadActive = computed(() => {
    return props.preload || (props.autoPreload && sectionPosition === 0 && blockPosition <= 1);
});

const { imageCacheBreaker } = useAhConfig();

// image src can be a single given url or part of a whole media object
const imgSrc = computed(() => props.mediaSrc ?? (props.media as AhMedia)?.url ?? '');

// disallow certain file extensions like svg
const hasDisallowedFileExtension = computed(() => props?.media?.fileExtension === 'svg');

// use given width/height from property or use media object width/height as fallback
const imgWidth = computed(() => {
    if (props.width) return parseInt(String(props.width));
    // @ts-ignore
    if (props.media && props.media?.metaData?.width) return parseInt(String(props.media.metaData.width));
    return undefined;
});
const imgHeight = computed(() => {
    if (props.height) return parseInt(String(props.height));
    // @ts-ignore
    if (props.media && props.media?.metaData?.height) return parseInt(String(props.media.metaData.height));
    return undefined;
});

const sharedClassAttributes = computed(() => {
    return {
        'w-full': !imgWidth.value || props.displayMode !== 'standard',
        'w-auto': imgWidth.value && props.displayMode === 'standard',
        'h-full': props.displayMode !== 'standard',
        'absolute inset-0': props.displayMode === 'cover',
        'object-cover': props.displayMode === 'cover',
    };
});

const hasError = ref(false);
const img = useImage();
const isImgForDefaultProvider = computed(() => {
    if (!imgSrc.value) return false;
    if (!imgSrc.value.startsWith('http') || hasDisallowedFileExtension.value) return true;
    try {
        const imgUrl = new URL(imgSrc.value);
        return img.options.domains.includes(imgUrl.hostname);
    } catch (_e) {
        return true;
    }
});
const provider = computed(() => (isImgForDefaultProvider.value ? undefined : 'nginx'));
const placeholderImage = img('/img/placeholder.jpg', {
    width: imgWidth.value,
    height: imgHeight.value,
    format: 'webp',
    fit: 'contain',
    enlarge: true,
    background: '#ffffff',
});

const imgSrcWithParams = computed(() => {
    if (provider.value && imgSrc.value && imageCacheBreaker.value) {
        const paramJoiner = imgSrc.value.includes('?') ? '&' : '?';
        return imgSrc.value + paramJoiner + 'c=' + imageCacheBreaker.value;
    }

    return imgSrc.value;
});

const handleError = () => {
    hasError.value = true;
};
</script>

<template>
    <NuxtImg
        v-if="imgSrc && !hasError"
        v-bind="$attrs"
        :src="imgSrcWithParams"
        :sizes="sizes"
        :width="imgWidth"
        :height="imgHeight"
        :loading="lazyLoad && !preloadActive ? 'lazy' : 'eager'"
        :preload="preloadActive"
        :class="sharedClassAttributes"
        :densities="noDensities ? 'x1' : 'x1 x2'"
        data-cmp-ab="1"
        :format="!provider ? 'webp' : undefined"
        :provider="provider"
        @error="handleError"
    />
    <img
        v-else
        v-bind="$attrs"
        :src="placeholderImage"
        :width="imgWidth"
        :height="imgHeight"
        :loading="lazyLoad && !preloadActive ? 'lazy' : 'eager'"
        :class="sharedClassAttributes"
        data-cmp-ab="1"
    />
</template>
